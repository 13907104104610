<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-bdelivery-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-8">
              <h3 class="mb-0">
                {{ salesDelivery.code }}
                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="editSalesDelivery()"
                  v-if="salesDelivery.status === DELIVERY_STATUS_DRAFT"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    salesDelivery.status === DELIVERY_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_DELETE_SALES_DELIVERIES)
                  "
                  type="danger"
                  icon
                  size="sm"
                  @click="deleteSalesDelivery()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="
                    () => {
                      salesDeliveryValidateFormModalOpened = true;
                    }
                  "
                  :disabled="loading"
                  v-if="
                    salesDelivery.items.length > 0 &&
                    salesDelivery.status === DELIVERY_STATUS_DRAFT
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-check-bold"></i>
                    {{ $t("SALES_DELIVERIES.VALIDATE") }}
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="viewSalesOrder()"
                  :disabled="loading"
                  v-if="salesDelivery.salesOrder"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-bag-17"></i>
                    {{ $t("SALES_DELIVERIES.VIEW_ORDER") }}
                  </span>
                </base-button>

                <base-button
                  type="danger"
                  icon
                  size="sm"
                  @click="cancelSalesDelivery()"
                  :disabled="loading"
                  v-if="canCancelSalesDelivery"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-fat-remove"></i>
                    {{ $t("SALES_DELIVERIES.CANCEL") }}
                  </span>
                </base-button>

                <base-button
                  type="info"
                  icon
                  size="sm"
                  @click="print()"
                  :disabled="loading || !salesDelivery.items?.length"
                  v-if="
                    salesDelivery.status !== DELIVERY_STATUS_DRAFT ||
                    salesDelivery.status === DELIVERY_STATUS_DRAFT
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="fa fa-file-pdf"></i>
                    {{ $t("COMMON.PRINT") }}
                  </span>
                </base-button>

                <base-button
                  type="success"
                  icon
                  size="sm"
                  @click="toggleMailModal()"
                  :disabled="loading"
                  v-if="
                    salesDelivery.status !== DELIVERY_STATUS_DRAFT ||
                    salesDelivery.status === DELIVERY_STATUS_DRAFT ||
                    salesDelivery.items?.length
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="fa fa-envelope"></i>
                    {{ $t("COMMON.SEND") }}
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>

              <notification-subscription
                :objectType="'sales-deliveries'"
                :objectId="salesDelivery.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap tabs-wrapper"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="details"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="false">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <sales-delivery-view-global :salesDelivery="salesDelivery" />
                </tab-pane>

                <tab-pane title="details" id="2" :active="true">
                  <span slot="title">
                    <i class="ni ni-bullet-list-67" />
                    {{ $t("COMMON.DETAILS") }}
                  </span>
                  <sales-delivery-view-details
                    :salesDelivery="salesDelivery"
                    @salesDeliveryItemsUpdated="get"
                  />
                </tab-pane>

                <tab-pane
                  title="files"
                  id="998"
                  v-if="
                    $currentUserCan($permissions.PERM_VIEW_ANY_FILES) &&
                    salesDelivery.organization
                  "
                >
                  <span slot="title">
                    <i class="ni ni-folder-17" />
                    {{ $t("COMMON.FILES") }}
                  </span>
                  <list-file-component :object="salesDelivery" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <sales-delivery-view-logs :salesDelivery="salesDelivery" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>

    <sales-delivery-view-validate-delivery-form
      v-if="
        salesDelivery.items.length > 0 &&
        salesDelivery.status === DELIVERY_STATUS_DRAFT
      "
      :salesDelivery="salesDelivery"
      :showModal="salesDeliveryValidateFormModalOpened"
      @closeSalesOrderDeliveryForm="
        () => {
          salesDeliveryValidateFormModalOpened = false;
        }
      "
      @salesDeliveryUpdated="get"
    />

    <modal :show="showMailModal" modal-classes="modal-secondary" size="xl">
      <sales-delivery-mail-modal-content
        :salesDelivery="salesDelivery"
        :get="get"
        :toggleMailModal="toggleMailModal"
      />
    </modal>
  </div>
</template>

<script>
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import {
  DELIVERY_STATUS_CANCELED,
  DELIVERY_STATUS_DRAFT,
  DELIVERY_STATUS_VALIDATED,
} from "@/constants/deliveries";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { TabPane, Tabs } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import SalesDeliveryViewGlobal from "./partials/SalesDeliveryViewGlobal.vue";
import SalesDeliveryViewDetails from "./partials/SalesDeliveryViewDetails.vue";
import SalesDeliveryViewValidateDeliveryForm from "./partials/SalesDeliveryViewValidateDeliveryForm.vue";
import SalesDeliveryViewLogs from "./partials/SalesDeliveryViewLogs.vue";
import defaultSalesDelivery from "./defaultSalesDelivery";
import SalesDeliveryMailModalContent from "./partials/SalesDeliveryMailModalContent";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    SalesDeliveryMailModalContent,
    SalesDeliveryViewGlobal,
    SalesDeliveryViewDetails,
    SalesDeliveryViewLogs,
    ListFileComponent,
    NotificationSubscription,
    SalesDeliveryViewValidateDeliveryForm,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      loading: false,
      showMailModal: false,
      salesDelivery: cloneDeep(defaultSalesDelivery),
      DELIVERY_STATUS_DRAFT: DELIVERY_STATUS_DRAFT,
      DELIVERY_STATUS_CANCELED: DELIVERY_STATUS_CANCELED,
      DELIVERY_STATUS_VALIDATED: DELIVERY_STATUS_VALIDATED,
      salesDeliveryValidateFormModalOpened: false,
    };
  },

  computed: {
    canCancelSalesDelivery() {
      if (
        this.salesDelivery.status === DELIVERY_STATUS_CANCELED ||
        this.salesDelivery.status === DELIVERY_STATUS_VALIDATED
      ) {
        return false;
      }
      return false;
    },
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("salesDeliveries/get", id);
        this.salesDelivery =
          this.$store.getters["salesDeliveries/salesDelivery"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editSalesDelivery(row) {
      this.$router.push({
        name: "Edit SalesDelivery",
        params: { id: this.salesDelivery.id },
      });
    },

    async validateSalesDelivery() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "salesDeliveries/validate",
          this.salesDelivery.id
        );
        this.salesDelivery =
          this.$store.getters["salesDeliveries/salesDelivery"];
        this.$notify({
          type: "success",
          message: this.$t("SALES_DELIVERIES.SALES_DELIVERY_VALIDATED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    toggleMailModal() {
      this.showMailModal = !this.showMailModal;
    },

    async cancelSalesDelivery() {
      const confirmation = await swal.fire({
        title: this.$t("SALES_DELIVERIES.CANCEL_SALES_DELIVERY_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch(
          "salesDeliveries/cancel",
          this.salesDelivery.id
        );
        this.salesDelivery =
          this.$store.getters["salesDeliveries/salesDelivery"];
        this.$notify({
          type: "success",
          message: this.$t("SALES_DELIVERIES.SALES_DELIVERY_CANCELED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async deleteSalesDelivery() {
      const confirmation = await swal.fire({
        title: this.$t("SALES_DELIVERIES.DELETE_THIS_SALES_DELIVERY"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "salesDeliveries/destroy",
            this.salesDelivery.id
          );
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("SALES_DELIVERIES.SALES_DELIVERY_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async viewSalesOrder() {
      this.$router.push(this.$objectViewRoute(this.salesDelivery.salesOrder));
    },

    goBack() {
      this.$router.push({ name: "List SalesDeliveries" });
    },

    async print() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "salesDeliveries/print",
          this.salesDelivery.id
        );

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url, "_blank");
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },
  },
};
</script>
